// extracted by mini-css-extract-plugin
export var box1 = "page-home-module--box1--LvYwb";
export var box2 = "page-home-module--box2--bKeOK";
export var men1 = "page-home-module--men1--3LI8B";
export var men3 = "page-home-module--men3--wXRe-";
export var men2 = "page-home-module--men2--3AS4B";
export var men4 = "page-home-module--men4--3y52x";
export var homepageMidbox = "page-home-module--homepageMidbox--1sRnZ";
export var redImage = "page-home-module--redImage--eilI-";
export var logoTagline = "page-home-module--logoTagline--N_rTJ";
export var wildside = "page-home-module--wildside--3LQs9";