import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './page-home.module.css'
import { SocialMediaLinks } from '../../utils'


const PageHome = ({ pageContext, location }) => {
    const page = pageContext.page

    const data = useStaticQuery(graphql`
    query {
        men1: file(relativePath: {eq: "men1.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 499
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        men2: file(relativePath: {eq: "men2.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        men3: file(relativePath: {eq: "men3.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        men4: file(relativePath: {eq: "men4.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        redImage: file(relativePath: {eq: "homepage_red_image.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 760
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
        logoTagline: file(relativePath: {eq: "premiere_logo_tagline.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 513
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
    }
    `)
        
    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.logoTagline?.childImageSharp.original.src))}/>

            <div className="relative">
                <div>
                    <div className={styles.box1}>
                        <div className={styles.men1}>
                            <GatsbyImage image={data.men1.childImageSharp.gatsbyImageData} style={{maxWidth:'499px', width:'100%'}} alt="Men 1"/>
                        </div>
                        <div className={styles.men3}>
                            <GatsbyImage image={data.men4.childImageSharp.gatsbyImageData} style={{maxWidth:'400px', width:'100%'}} alt="Men 4"/>
                        </div>
                    </div>

                    <div className={styles.box2}>
                        <div className={styles.men2}>
                            <GatsbyImage image={data.men2.childImageSharp.gatsbyImageData} style={{maxWidth:'480px', width:'100%'}} alt="Men 2"/>
                        </div>
                        <div className={styles.men4}>
                            <GatsbyImage image={data.men3.childImageSharp.gatsbyImageData} style={{maxWidth:'400px', width:'100%'}} alt="Men 3"/>
                        </div>
                    </div>
                </div>

                <div className={styles.homepageMidbox}>
                    <div className="flex-1">
                        <div className={styles.redImage}>
                            <GatsbyImage image={data.redImage.childImageSharp.gatsbyImageData} style={{maxWidth:'760px', width:'100%', margin:0}} alt="Cool, walks to his own beat, makes his own decisions, can impose his will, charming but not loud, quietly charismatic, dares to be dangerous but never overplays his hand. That's what a PREMIERE man is like. Are you?"/>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className={styles.logoTagline}>
                            <GatsbyImage image={data.logoTagline.childImageSharp.gatsbyImageData} style={{maxWidth:'513px', width:'100%', margin:0}} alt="PREMIERE Condoms - Walk on the wild side"/>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default PageHome





